import { createApp, ref } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import Antd, { notification } from 'ant-design-vue'
import App from './App.vue'
// import './registerServiceWorker' // Commented to prevent service worker from loading because of caching.
import './assets/styles/theme.less'
import SecureLS from 'secure-ls'
import axios from 'axios'
import { Model } from 'vue-api-query'
import { authStore } from '@/stores/auth'

const pinia = createPinia()
const app = createApp(App)
const token = ref(new SecureLS({ isCompression: false }).get('token') || null)

Model.$http = axios

if (token.value) { axios.defaults.headers.common.Authorization = token.value }
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.response.use(function (response) {
  return response
}, function (e) {
  const auth = authStore()
  if (e.response.status === 401 && !e.response.config.url.includes('logout') && !e.response.config.url.includes('login')) {
    notification.destroy()
    auth.logout(true)
  }
  return Promise.reject(e)
})

app.use(pinia)
app.use(router)
app.use(Antd)
app.mount('#app')
